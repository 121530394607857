import { Component } from 'vue-property-decorator'
import CommonLayoutMixin from 'booksprout-app/src/components/mixins/commonLayout'

@Component
export default class NotLoggedInDefaultLayout extends CommonLayoutMixin {
  shouldShowLayout = false

  // TODO: https://app.clubhouse.io/booksprout/story/2132/bs-a-payment-views-on-login-need-building
  //  task "Go back action (mobile)"
  goBack () {

  }

  get logoutVisible () {
    return this.$route.params.stage === 'verify-email'
  }

  mounted () {
    if (
      this.isAuthenticated
      && ['login', 'sign-up'].includes(this.$route.name || '')
      && !this.$route.fullPath.includes('/sign-up/select-role')
      && !this.$route.fullPath.includes('/sign-up/verify-email')
    ) {
      this.navigateToOtherApp(this.authenticatedUser.authorRole ? 'publisher' : 'reviewer')
    } else {
      this.shouldShowLayout = true
    }
  }
}
